import { useContext, useState } from "react";
import { BlogsContext } from "../contexts/BlogsContext";
import BlogsShimmer from "./BlogsShimmer";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../css/blogs.css";

function Blogs() {
  const { blogs } = useContext(BlogsContext);
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const filteredBlogs = blogs.filter((blog) => blog !== null && blog !== undefined);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {filteredBlogs.length === 0 ? (
        <BlogsShimmer />
      ) : (
        <div className="blogs-wrapper">
          <div className="blogs-container">
            {filteredBlogs.reverse().map((item, index) => (
              <Link
              to={`/blogs/${item.CATEGORY.toLowerCase().replaceAll(" ", "-").replaceAll("&", "and")}/${item.key}/${item.HEADING.toLowerCase().replaceAll(" ", "-").replaceAll("%", "percentage")}`}
                className="first-box"
                key={index}
                onClick={goToTop}
              >
                <img
                  src={item.BANNER}
                  alt={item.HEADING}
                  onLoad={() => setIsBannerLoaded(true)}
                />
                <Skeleton height={300} style={!isBannerLoaded ? {} : { display: "none" }} />
                <div className="description-box">
                  <div className="description-box-heading">
                    <p>{item.CATEGORY}</p>
                    <h3>{item.HEADING}</h3>
                  </div>
                  <div className="description-box-date">
                    <p>{item.CREATEDON}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Blogs;
