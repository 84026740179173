import React from "react";
import brands from "../images/brands.jpg";
import brands2 from "../images/brands_2.png";
import "../css/stores.css";

function Stores() {
  return (
    <>
      <div className="stores-wrapper">
        <div className="stores-img">
          <img src={brands2} alt="" />
        </div>
        <div className="stores-heading">
          <p>
            Earn cashback for shopping at all your favorite stores on Hutti app
          </p>
          {/* <p>Sound too good to be true? Here’s the deal: Stores pay us a commission for sending you their way. We share that with you as Cash Back. Everyone</p> */}
        </div>
      </div>

      <div className="stores-wrapper-mobile">
        <div className="stores-heading mob">
          <p id='stores-heading'>
            Earn cashback for shopping at all your favorite stores on Hutti app
          </p>
          {/* <p>Sound too good to be true? Here's the deal: Stores pay us a commission for sending you their way. We share that with you as Cash Back. Everyone</p> */}
        </div>
        <div className="stores-img">
          <img src={brands} alt="" />
        </div>
      </div>
    </>
  );
}

export default Stores;
