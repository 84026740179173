import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import banner from '../images/banr.png';
import mob from '../images/phone007.png';
import qr from '../images/huttiQR.jpeg';
import "../css/banner.css";

function Banner() {
  const [isQrBoxVisible, setIsQrBoxVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1500) {
        setIsQrBoxVisible(false);
      } else {
        setIsQrBoxVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="banner-wrapper">
        <div className="banner-text">
          <p id="heading">All-in-one Super App for <br /> Shopping and Cashback</p>
          <p id="sub-heading">
          Earn Cashback on every purchase from your <br /> favorite brands and stores
          </p>
          <div className={`qrBox ${isQrBoxVisible ? "" : "hidden"}`}>
            <img src={qr} alt="" />
            <p>Download <br/> Hutti</p>
          </div>
        </div>
        <div className="banner-img">
          <img src={banner} alt="Shopper with shopping bags" id="lady-img" />
        </div>
      </div>
      <div className="banner-mobile">
        <div className="banner-heading">
          <p>All-in-one <br /> Super App for</p>
          <p> Shopping and Cashback</p>
          <span id="sub-heading">Earn Cashback on every purchase <br/> from your favorite brands and stores</span>
        </div>
        <div className="banner-button">
          <Link to='https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste%26utm_campaign%3Dhutti'>
            <button>Get the App</button>
          </Link>
        </div>
        <div className="banner-mobile-image">
          <img src={mob} alt="" />
        </div>
      </div>
    </>
  );
}

export default Banner;
