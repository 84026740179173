import { createContext, useEffect, useState } from "react";
import { getHuttiBlogs } from "../Connection";

export const BlogsContext = createContext();

export const BlogsProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  // const [blogObject, setBlogObject] = useState([]);

  useEffect(() => {
    getHuttiBlogs((res) => {
      const blogsArray = Object.entries(res).map(([key, value]) => {
        if (value !== null) {
          return {
            ...value,
            key,
          };
        }
        return null; 
      }).filter((blog) => blog !== null); // Remove null entries from the array
        setBlogs(blogsArray);
    });
    
  }, []);

  // console.log(Object.en(res))
  // // console.log(blogsArray);

  // // blogsArray.sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp));

  // // const blogsObject = blogsArray.reduce((acc, blog) => {
  // //   const { key, ...rest} = blog;
  // //   acc[key] = rest;
  // //   return acc;
  // // }, {});
  // setBlogObject(res);


  return (
    <BlogsContext.Provider value={{ blogs }}>
      {children}
    </BlogsContext.Provider>
  )
}