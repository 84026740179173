import { Route, Routes } from "react-router-dom";
import Website from "./Website";
import Privacy from "./Privacy";
import TermsAndConditions from "./TermsAndConditions";
import AntiSpamPolicy from "./AntiSpamPolicy";
import AboutUs from "./AboutUs";
import MainHome from "./MainHome";
import Blogs from "./Blogs";
import BlogDetails from "./BlogDetails";

function RouterPath() {
  const element = (
    <Routes>
      <Route path="/" element={<MainHome />}>
        <Route path="/" element={<Website />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="blogs/:category/:index/:heading" element={<BlogDetails />} />
        <Route path="/about-hutti-cashback" element={<AboutUs />} />
      </Route>
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/anti-spam-policy" element={<AntiSpamPolicy />} />
    </Routes>
  );
  return element;
}

export default RouterPath;
