import axios from "axios";

const URL = "https://api.connecttocms.in";
// const URL = "http://localhost:84";

export const getHuttiBlogs = async (callback) => {
  const result = await axios.get(URL + "/hutti/getHuttiBlogs");
  callback(result.data);
};

export const getHuttiBlogById = async (index, callback) => {
  const result = await axios.get(URL + "/hutti/getHuttiBlogByIndex", {
    params: { index: index },
  });
  callback(result.data);
};
