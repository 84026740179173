import React, { useEffect } from "react";
import Banner from './Banner';
import Stores from './Stores';
import Features from './Features';
import Steps from './Steps';
import '../css/main.css'
import Aos from "aos";
import "aos/dist/aos.css";

function Website() {

    useEffect(() => {
      Aos.init({
        duration: 1000,
        disable: 'mobile'
      });
    }, []);

  return (
    <>
      <Banner />
      <Stores />
      <Features />
      <Steps />
    </>
  );
}

export default Website;
