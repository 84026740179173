import React, { useEffect, useState } from "react";
import { getHuttiBlogById } from "../Connection";
import { useContext } from "react";
import { BlogsContext } from "../contexts/BlogsContext";
// import instagram from "../images/instagram.png";
// import telegram from "../images/telegram.png";
// import facebook from "../images/facebook.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useParams } from "react-router-dom";
import DetailsShimmer from "./DetailsShimmer";
import "../css/blogdetails.css";

function BlogDetails() {
  const [blogData, setBlogData] = useState({});
  // const [isSocialMediaVisible, setIsSocialMediaVisible] = useState(true);
  const { blogs } = useContext(BlogsContext);
  const { index } = useParams();
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const filteredBlogs = blogs.filter(
    (blog) => blog !== null && blog !== undefined
  );

  useEffect(() => {
    getHuttiBlogById(index, (res) => {
      setBlogData(res);
    });

    // const handleScroll = () => {
    //   if (window.scrollY > 1000) {
    //     setIsSocialMediaVisible(false);
    //   } else {
    //     setIsSocialMediaVisible(true);
    //   }
    // };

    // window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
  }, [index]);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  // Generate share links
  // const generateShareLinks = () => {
  //   const currentUrl = window.location.href;
  //   const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //     currentUrl
  //   )}`;
  //   const instagramShare = `https://www.instagram.com/?url=${encodeURIComponent(
  //     currentUrl
  //   )}`; // Instagram doesn't support direct share links
  //   const telegramShare = `https://t.me/share/url?url=${encodeURIComponent(
  //     currentUrl
  //   )}&text=${encodeURIComponent(blogData.HEADING || "")}`;

  //   return { facebookShare, instagramShare, telegramShare };
  // };

  // const { facebookShare, instagramShare, telegramShare } = generateShareLinks();

  return (
    <>
      {blogData.DETAILS !== undefined ? (
        <div className="blog-details-wrapper">
          <div className="blog-details-box">
            <div className="first-block">
              <h1>{blogData["HEADING"]}</h1>
              <p id="blogDate">{blogData["CREATEDON"]}</p>

              <img
                src={blogData["BANNER"]}
                alt=""
                onLoad={() => setIsBannerLoaded(true)}
              />
              <div
                id="blog-description"
                dangerouslySetInnerHTML={{ __html: blogData["INTRO"] }}
                style={isBannerLoaded ? {} : { display: "none" }}
              />
              <Skeleton height={300}  style={!isBannerLoaded ? {} : { display: "none" }}/>
              {/* <br /> */}
              {blogData.DETAILS.map((subitem, subindex) => (
                <div
                  key={subindex}
                  style={isBannerLoaded ? {} : { display: "none" }}
                >
                  <div
                    id="blog-subheading"
                    dangerouslySetInnerHTML={{ __html: subitem.SUBHEADING }}
                  />
                  <br />
                  <div className="details-description">
                    {subitem.DESCRIPTION.split("\\n\\n").map(
                      (line, lineIndex) => (
                        <div key={lineIndex}>
                          <div
                            id="blog-info"
                            dangerouslySetInnerHTML={{ __html: line }}
                          />
                          <br />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div
            className={`blog-social ${isSocialMediaVisible ? "" : "hidden"}`}
          >
            <a
              href={facebookShare}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href={instagramShare}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              href={telegramShare}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegram} alt="Telegram" />
            </a>
          </div> */}
          <div className="blog-cards-wrapper">
            {filteredBlogs.reverse().map((blog, index) =>
              blog.HEADING !== blogData.HEADING ? (
                <Link
                  to={`/blogs/${blog.CATEGORY.toLowerCase().replaceAll(" ","-").replaceAll('&','and')}/${blog.key}/${blog.HEADING.toLowerCase().replaceAll(" ", "-").replaceAll('%','percentage')}`}
                  className="blog-card"
                  key={blog.HEADING}
                  onClick={goToTop}
                >
                  <img src={blog.BANNER} alt="" />
                </Link>
              ) : null
            )}
          </div>
        </div>
      ) : (
        <DetailsShimmer />
      )}
    </>
  );
}

export default BlogDetails;
